// import { Button, Container, Typography } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { getCart } from "../../actions/cartAction";
// import CartCard from "./CartCard";
// import NumberInput from "../layout/NumberInput";
// import { InputNumber } from "antd";
// import { useNavigate } from "react-router-dom";
// import LinearLoader from "../layout/Loader/LinearLoader";

// const CartPage = () => {
//   const dispatch = useDispatch();

//   const [disableCheckout, SetDisableCheckout] = useState(false);
//   const { error, loading, cartData } = useSelector((state) => state.cart);
//   let cartList = cartData ? cartData.data.data : [];

//   // console.log("cartData", cartList);
//   // let cartProductList = cartData.data;

//   const { isAuthenticated, user } = useSelector((state) => state.user);
//   // console.log("isAuthenticated", user && user.userData);

//   console.log("cartList", cartList);

//   const navigate = useNavigate();
//   const userId = user && user.id;
//   useEffect(() => {
//     dispatch(getCart());
//   }, [dispatch]);

//   useEffect(() => {
//     console.log("disableCheckout", disableCheckout);
//   }, [disableCheckout]);

//   if (loading) {
//     return (
//       <>
//         <LinearLoader />
//       </>
//     );
//   }

//   if (cartData == null) {
//     console.log(error);
//     return (
//       <>
//         <Container style={{ minHeight: "45rem" }}>
//           <div
//             style={{
//               height: "45rem",
//               width: "100%",
//               backgroundColor: "#fdf5e6",
//               display: "flex",
//               alignContent: "center",
//               justifyContent: "center",
//             }}
//           >
//             <Typography style={{ position: "relative", top: "30%" }}>
//               Please Login to Access Cart !
//             </Typography>
//           </div>
//         </Container>
//       </>
//     );
//   }

//   return (
//     <>
//       <div style={{ display: "flex" }}>
//         <Container style={{ width: "170%" }}>
//           {cartList.length == 0 && (
//             <Typography
//               variant="h3"
//               style={{
//                 textAlign: "center",
//                 paddingTop: "5rem",
//                 fontSize: "12px",
//               }}
//             >
//               Seems to empty...
//             </Typography>
//           )}
//           <Container
//             style={{
//               minHeight: "50rem",
//               backgroundColor: "#ffffff",
//               marginTop: "1rem",
//               marginBottom: "1rem",
//               width: "100%",
//             }}
//           >
//             <div>
//               {cartList &&
//                 cartList &&
//                 cartList.map((item) => (
//                   <div style={{ padding: "8px" }}>
//                     <CartCard
//                       key={item.id}
//                       itemId={item.id}
//                       product={item}
//                       SetDisableCheckout={SetDisableCheckout}
//                     />
//                   </div>
//                 ))}
//             </div>
//           </Container>
//         </Container>
//         {cartList.length > 0 && (
//           <Container style={{ backgroundColor: "#ffffff" }}>
//             <div
//               style={{
//                 display: "flex",
//                 flexDirection: "column",
//                 paddingTop: "1.5rem",
//               }}
//             >
//               <Typography
//                 variant="h5"
//                 style={{ paddingBottom: "0rem", fontSize: "14px" }}
//               >
//                 {cartData && cartData.data.total_quantity} Items
//               </Typography>
//               <Typography
//                 variant="h5"
//                 style={{ paddingBottom: "2rem", fontSize: "14px" }}
//               >
//                 Subtotal ₹{cartData && cartData.data.sub_total}
//               </Typography>

//               <Button
//                 variant="contained"
//                 href="/checkout"
//                 style={{
//                   color: "black",
//                   backgroundColor: disableCheckout ? "" : "#ffcb68",
//                 }}
//                 disabled={disableCheckout}
//               >
//                 Proceed To Check Out
//               </Button>
//               {disableCheckout && (
//                 <div>
//                   <div style={{ color: "#9e2a2b" }}>
//                     Please remove out of stock items,
//                   </div>
//                   <div style={{ color: "#9e2a2b" }}>
//                     you can add it to your wishlist so we can notify you when
//                     back in sotck.
//                   </div>
//                 </div>
//               )}
//             </div>
//           </Container>
//         )}
//       </div>
//       <div style={{ backgroundColor: "#fdf5e6" }}></div>
//     </>
//   );
// };

// export default CartPage;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCart } from "../../actions/cartAction";
import CartCard from "./CartCard";
import { Container, Grid, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LinearLoader from "../layout/Loader/LinearLoader";

const CartPage = () => {
  const dispatch = useDispatch();
  const [disableCheckout, SetDisableCheckout] = useState(false);
  const { error, loading, cartData } = useSelector((state) => state.cart);
  let cartList = cartData ? cartData.data.data : [];

  const { isAuthenticated, user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const userId = user && user.id;

  useEffect(() => {
    if (!userId) {
      // navigate("/login");
      navigate("/login", { state: { from: window.location.pathname } });
    }
  }, [userId, navigate]);

  useEffect(() => {
    dispatch(getCart());
  }, [dispatch]);

  if (loading) {
    return <LinearLoader />;
  }

  if (cartData == null) {
    return (
      <Container
        sx={{
          minHeight: "45rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography>Please Login to Access Cart!</Typography>
      </Container>
    );
  }

  return (
    <Container style={{ padding: "1rem", minHeight: "45rem" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          {cartList.length === 0 ? (
            <Typography variant="h5" sx={{ textAlign: "center", pt: 5 }}>
              Your cart seems empty...
            </Typography>
          ) : (
            cartList.map((item) => (
              <CartCard
                key={item.id}
                itemId={item.id}
                product={item}
                SetDisableCheckout={SetDisableCheckout}
              />
            ))
          )}
        </Grid>
        {cartList.length > 0 && (
          <Grid
            item
            xs={12}
            md={4}
            sx={{ display: "flex", flexDirection: "column", pt: 1.5 }}
          >
            <Typography variant="h5" sx={{ fontSize: 14 }}>
              {cartData.data.total_quantity} Items
            </Typography>
            <Typography variant="h5" sx={{ pb: 2, fontSize: 14 }}>
              Subtotal: ₹{cartData.data.sub_total}
            </Typography>
            <Button
              variant="contained"
              onClick={() => navigate("/checkout")}
              disabled={disableCheckout}
              sx={{
                bgcolor: disableCheckout ? undefined : "#ffcb68",
                color: "black",
              }}
            >
              Proceed To Checkout
            </Button>
            {disableCheckout && (
              <Typography color="#9e2a2b" sx={{ mt: 2 }}>
                Please remove out of stock items. You can add them to your
                wishlist to be notified when back in stock.
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default CartPage;
