import { Button, Container, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import WishlistCard from "./WishlistCard";
import { getCart } from "../../actions/cartAction";
import { loadFavourites } from "../../actions/wishlistAction";
import LinearLoader from "../layout/Loader/LinearLoader";
import { useNavigate } from "react-router-dom";

const Wishlist = () => {
  const dispatch = useDispatch();
  const { error, loading, wishlist } = useSelector((state) => state.wishlist);
  let cartList = wishlist ? wishlist : [];

  // console.log("wishlist", wishlist);
  // let cartProductList = wishlist.data;

  const { isAuthenticated, user } = useSelector((state) => state.user);
  // console.log("isAuthenticated", user && user.userData);

  const userId = user && user.id;

  const navigate = useNavigate();
  useEffect(() => {
    if (!userId) {
      // navigate("/login");
      navigate("/login", { state: { from: window.location.pathname } });
    }
  }, [userId, navigate]);

  useEffect(() => {
    // dispatch(());
    dispatch(loadFavourites());
  }, [dispatch]);

  if (!loading && !isAuthenticated) {
    return (
      <>
        <Container style={{ minHeight: "45rem" }}>
          <div
            style={{
              height: "45rem",
              width: "100%",
              backgroundColor: "#fdf5e6",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Typography style={{ position: "relative", top: "30%" }}>
              Please login to access wishlist !
            </Typography>
          </div>
        </Container>
      </>
    );
  }

  if (wishlist?.length == 0) {
    return (
      <>
        <Container
          style={{
            minHeight: "45rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <Typography>Start adding products to your wishlist</Typography>
          </div>
        </Container>
      </>
    );
  }

  if (!wishlist) {
    return <LinearLoader />;
  }

  return (
    <>
      <Container style={{ backgroundColor: "#ffffff", minHeight: "50rem" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "1.5rem",
          }}
        >
          {wishlist &&
            wishlist.map((product) => <WishlistCard product={product} />)}

          {/* <Typography variant="h5" style={{ paddingBottom: "0rem" }}>
              {wishlist && wishlist.total_quantity} Items
            </Typography>
 */}
        </div>
      </Container>
    </>
  );
};

export default Wishlist;
