import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Container, Rating, Typography, Grid } from "@mui/material"; // Import Grid from MUI
import { CCarousel, CCarouselItem, CImage } from "@coreui/react";
import "./productdetails.css";
import {
  getProductDetails,
  addItemsToCart,
  getSimilarProducts,
} from "../../actions/productAction";
import { addToWishlist, fetchFavorites } from "../../actions/wishlistAction";
import VariantSelector from "./Variants";
import { modifyCart } from "../../actions/cartAction";
import ProductCarousel from "./ProductCarousel";
import OutlinedCard from "../Home/Card.jsx";
import ReviewCard from "./ReviewCard.jsx";
import { BASE_URL } from "../../App.js";
import LinearLoader from "../layout/Loader/LinearLoader.jsx";

// const BASE_URL = "https://webbackend.kitablovers.com";
// const BASE_URL = "http://webapptest.kitablovers.com";

// const BASE_URL = "http://localhost:4000";

const ProductDetailPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [reviews, setReviews] = useState([]);

  const fetchReviews = async (productId) => {
    try {
      const response = await fetch(BASE_URL + "/app/middleware/v1/rating", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ product_id: productId }),
      });
      const data = await response.json();
      setReviews(data.data); // Assuming the response has a `reviews` field with an array of review objects
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        await dispatch(getProductDetails(id));
      } catch (error) {
        console.error(error);
      }
    };

    window.scrollTo(0, 0);
    fetchProductDetails();
  }, [id, dispatch]);

  const similarproducts = useSelector(
    (state) =>
      state.similarProducts &&
      state.similarProducts.products &&
      state.similarProducts.products.data
  );

  const { product, loading, error } = useSelector(
    (state) => state.productDetails
  );
  const { user } = useSelector((state) => state.user);
  const userId = (user && user.id) || 0;
  const productObj = product?.data?.[0];
  const productType = productObj?.type;

  const navigate = useNavigate();
  // useEffect(() => {
  //   if (!userId) {
  //     // navigate("/login");
  //     navigate("/login", { state: { from: window.location.pathname } });
  //   }
  // }, [userId, navigate]);

  const showWarningMessage = productObj?.name
    ?.toLowerCase()
    .includes("load the box");

  useEffect(() => {
    if (showWarningMessage) {
      window.location.href = "/loadtheboxevent";
    }
  }, [showWarningMessage]);

  // console.log("productObj", productObj);

  useEffect(() => {
    if (productObj && productObj.category_id) {
      dispatch(
        getSimilarProducts({
          category_id: productObj.category_id,
          limit: 100,
          offset: 0,
        })
      );
    }
  }, [productObj, dispatch]);

  const modifyCartHandlerbuynow = (variantId) => {
    if (userId === undefined || userId === 0) {
      navigate("/login", { state: { from: window.location.pathname } });
    } else {
      navigate("/buynow/" + productObj?.id + "/" + selectedVariant?.id);
    }
  };
  const modifyCartHandler = (variantId) => {
    if (userId === undefined || userId === 0) {
      navigate("/login", { state: { from: window.location.pathname } });
      // alert("Please login to add items to cart");
    } else {
      dispatch(modifyCart(userId, variantId, 1));
      alert("Items added to cart");
    }
  };

  const modifyFavouritesHandler = (variantId) => {
    if (userId === undefined || userId === 0) {
      // alert("Please login to add items to wishlist");
      navigate("/login", { state: { from: window.location.pathname } });
    } else {
      dispatch(addToWishlist(userId, variantId));
      dispatch(fetchFavorites());
      alert("Item added to wishlist");
    }
  };

  const variants = productObj?.variants;
  // console.log("variants", variants);
  // console.log("variants.length", variants?.length);

  const [selectedVariant, setSelectedVariant] = useState(
    variants?.length > 0 ? variants[0] : null
  );

  useEffect(() => {
    var availableVariant = "";
    if (productType == "simple_product") {
      availableVariant = variants?.find(
        (variant) =>
          parseInt(variant.availability) > 0 || variant.availability == ""
      );
    } else {
      availableVariant = variants?.find(
        (variant) => parseInt(variant.availability) > 0
      );
    }

    if (availableVariant) {
      setSelectedVariant(availableVariant);
    } else {
      setSelectedVariant(null);
    }
  }, [variants]);

  const isOutOfStock = !selectedVariant;

  const handleVariantClick = (variant) => {
    if (variant.availability === "0") {
      console.warn("This variant is out of stock.");
    } else {
      setSelectedVariant(variant);
    }
  };

  useEffect(() => {
    fetchReviews(productObj?.id);
  }, [productObj]);

  if (!productObj) {
    return <LinearLoader />;
  }

  return (
    <>
      {productObj && productObj.id == id && (
        <Grid container className="first-container">
          {" "}
          {/* Use Grid container */}
          {/* <Grid item xs={5}> */}
          <Grid item xs={12} sm={5} md={5}>
            {" "}
            {/* Specify the width for different screen sizes */}
            <div className="ProductDetails">
              {/* <div style={{ maxWidth: "40rem", maxHeight: "40rem" }}> */}
              <div className="productcarousel">
                {productObj && <ProductCarousel product={productObj} />}
              </div>
              {/* </div> */}
            </div>
          </Grid>
          {/* <Grid
            item
            xs={7}
            style={{ paddingTop: "2rem", paddingRight: "10rem" }}
          > */}
          <Grid item xs={12} sm={7} md={7} className="second-container">
            <Typography
              variant="h4"
              style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
            >
              {productObj?.name}
            </Typography>
            <div style={{ paddingLeft: "1rem", paddingTop: "1rem" }}>
              <Rating
                name="read-only"
                value={parseFloat(productObj?.rating)}
                readOnly
                size="medium"
                precision={0.5}
              />
            </div>
            <Typography style={{ paddingTop: "1rem", paddingLeft: "1rem" }}>
              {productObj?.short_description}
            </Typography>
            {variants?.length > 1 && (
              <div
                className="variants"
                style={{ paddingRight: "2rem", paddingLeft: "1rem" }}
              >
                <div>{!isOutOfStock && <label>Select Variant:</label>}</div>
                <div
                  className="variant-selector"
                  style={{ paddingTop: "1rem" }}
                >
                  <div className="variant-buttons">
                    {isOutOfStock ? (
                      <Typography variant="subtitle1" color="error">
                        Product Out of Stock
                      </Typography>
                    ) : (
                      variants?.map(
                        (variant) =>
                          variant.availability && (
                            <Button
                              key={variant.id}
                              className={`${
                                selectedVariant?.id === variant.id
                                  ? "selected"
                                  : ""
                              } variant-button`}
                              onClick={() => handleVariantClick(variant)}
                              variant="contained"
                              style={{
                                fontSize:
                                  "clamp(0.2rem, 0.3043rem + 3.4783vw, 0.6rem)",
                                borderRadius: "2rem",
                              }}
                            >
                              {variant?.variant_values}{" "}
                              {variant.availability === "0" && "(Out of Stock)"}
                            </Button>
                          )
                      )
                    )}
                  </div>
                </div>
              </div>
            )}
            <div style={{ paddingLeft: "1rem" }}>
              {!isOutOfStock && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "2rem",
                  }}
                >
                  <div
                    style={{ color: "black", fontWeight: "bold" }}
                    id="selectedPriceDisplay"
                  >
                    Price: ₹{selectedVariant?.special_price}
                  </div>
                  <div
                    id="originalPriceDisplay"
                    style={{
                      textDecoration: "line-through",
                      color: "#696663",
                      paddingLeft: "1rem",
                    }}
                  >
                    Price: ₹{selectedVariant?.price}
                  </div>
                </div>
              )}
            </div>
            {/* <div
              className="buttons"
              style={{ paddingTop: "2rem", paddingLeft: "0rem" }}
            >
              <Button
                variant="contained"
                className="MuiButton-contained-custom"
                style={{ marginRight: "2rem", fontSize: "12px" }}
                onClick={() => modifyCartHandler(selectedVariant?.id)}
                disabled={isOutOfStock}
              >
                Add to cart
              </Button>
              <Button
                className="MuiButton-contained-custom"
                variant="contained"
                style={{ marginRight: "2rem", fontSize: "12px" }}
                onClick={() => modifyFavouritesHandler(productObj?.id)}
              >
                Add to Wishlist
              </Button>
              <Button
                className="MuiButton-contained-custom"
                variant="contained"
                style={{ marginRight: "2rem", fontSize: "12px" }}
                onClick={() => modifyFavouritesHandler(productObj?.id)}
                href={`/review/${productObj?.id}`}
              >
                Submit Review
              </Button>
            </div> */}
            <div
              className="buttons"
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))", // Creates as many columns as can fit, each at least 120px wide
                gap: "1rem", // Consistent gap between grid items
                // paddingTop: "2rem",
                // paddingLeft: "1rem",
                // paddingRight: "1rem",
                margin: "1rem",
              }}
            >
              <Button
                variant="contained"
                className="MuiButton-contained-custom"
                style={{ fontSize: "12px" }}
                onClick={() => modifyCartHandler(selectedVariant?.id)}
              >
                Add to cart
              </Button>
              <Button
                variant="contained"
                className="MuiButton-contained-custom"
                style={{ fontSize: "12px" }}
                onClick={() => modifyCartHandlerbuynow(selectedVariant?.id)}
                // href={"/buynow/" + productObj?.id + "/" + selectedVariant?.id}
              >
                Buy Now
              </Button>
              <Button
                className="MuiButton-contained-custom"
                variant="contained"
                style={{ fontSize: "12px" }}
                onClick={() => modifyFavouritesHandler(productObj?.id)}
              >
                Add to Wishlist
              </Button>
              <Button
                className="MuiButton-contained-custom"
                variant="contained"
                disabled={userId === 0}
                style={{ fontSize: "12px" }}
                // onClick={() => modifyFavouritesHandler(productObj?.id)}
                href={`/review/${productObj?.id}`}
              >
                Submit Review
              </Button>
            </div>
            {(userId === "" || userId == undefined || userId === 0) && (
              <Typography style={{ paddingLeft: "1rem" }}>
                Please Login to add items to cart and wishlist.
              </Typography>
            )}

            <div
              style={{ paddingLeft: "1rem", paddingTop: "2rem" }}
              dangerouslySetInnerHTML={{ __html: productObj?.description }}
            />
          </Grid>
          {/* <Grid item xs={12} style={{ padding: "5rem" }}> */}
          <Grid item xs={12} style={{ padding: "2rem" }}>
            <Typography variant="h5" style={{ paddingBottom: "2rem" }}>
              Recommended For you
            </Typography>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                overflow: "scroll",
                padding: "1rem",
              }}
            >
              {similarproducts &&
                similarproducts?.length > 0 &&
                similarproducts.map((chunk, chunkIndex) => (
                  <div className="carouselContent" key={chunk.id}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        overflow: "scroll",
                      }}
                    >
                      <OutlinedCard product={chunk} />
                    </div>
                  </div>
                ))}
            </div>
          </Grid>
          <Grid item xs={8} style={{ padding: "5rem" }}>
            <Typography variant="h5" style={{ paddingBottom: "2rem" }}>
              Reviews
            </Typography>

            <div className="reviewsWrapper">
              {reviews?.length > 0 ? (
                reviews.map((review) => (
                  <ReviewCard key={review.id} review={review} />
                ))
              ) : (
                <Typography>No reviews yet</Typography>
              )}
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ProductDetailPage;
