import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Rating,
  Typography,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  addToWishlist,
  loadFavourites,
  removeFromWishlist,
} from "../../actions/wishlistAction";
import { modifyCart } from "../../actions/cartAction";

const SearchCard = (props) => {
  const { product } = props;

  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const userId = user?.id;

  const navigate = useNavigate();
  useEffect(() => {
    if (!userId) {
      // navigate("/login");
      navigate("/login", { state: { from: window.location.pathname } });
    }
  }, [userId, navigate]);

  const variants = product?.variants || [];
  const [selectedVariant, setSelectedVariant] = useState(variants[0]);

  const getLowestPrice = (variants) => {
    const availableVariants = variants.filter(
      (variant) =>
        parseInt(variant.availability) > 0 || variant.availability === ""
    );
    if (availableVariants.length === 0) {
      return null;
    }
    return availableVariants.reduce((lowest, variant) => {
      return lowest.special_price < variant.special_price ? lowest : variant;
    }).special_price;
  };

  const lowestPrice = getLowestPrice(variants);

  useEffect(() => {
    setSelectedVariant(variants[0]);
  }, [variants]);

  const handleVariantClick = (variant) => {
    setSelectedVariant(variant);
  };

  const addToWishlistHandler = () => {
    if (userId === undefined) {
      alert("Please login to add items to wishlist");
    } else {
      dispatch(addToWishlist(userId, selectedVariant.product_id));
      dispatch(loadFavourites());
    }
  };

  const modifyCartHandler = () => {
    if (userId === undefined) {
      alert("Please login to add items to cart");
    } else {
      const availableVariant = variants?.find(
        (variant) => parseInt(variant.availability) > 0
      );

      dispatch(modifyCart(userId, availableVariant?.id, 1));
      alert("Item added to cart");
    }
  };

  const shouldShowPrice = !product?.name
    ?.toLowerCase()
    .includes("load the box");

  return (
    <>
      <div>
        <Card
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            margin: "1rem",
            border: "1px solid #564c40",
            borderRadius: "0px",
            boxShadow: "unset",
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sm={8}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <CardContent>
                <Typography
                  component="div"
                  variant="h5"
                  style={{
                    fontSize: "1rem",
                    marginLeft: "2rem",
                    maxWidth: "40ch",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Link
                    to={"/product/" + product.id}
                    style={{ all: "unset", cursor: "pointer" }}
                  >
                    {product.name}
                  </Link>
                </Typography>
                {shouldShowPrice && (
                  <div style={{ marginTop: "1rem" }}>
                    {lowestPrice === null ? (
                      <Button
                        variant="contained"
                        onClick={() => modifyCartHandler()}
                        disabled={true}
                        style={{
                          marginLeft: "2rem",
                          backgroundColor: "#e76f51",
                          color: "black",
                          fontSize: "0.7rem",
                        }}
                      >
                        Out of Stock
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() => modifyCartHandler()}
                        style={{
                          marginLeft: "2rem",
                          backgroundColor: "#ffcb69",
                          color: "black",
                          fontSize: "0.7rem",
                        }}
                      >
                        Add to cart
                      </Button>
                    )}

                    <Button
                      variant="text"
                      onClick={() => addToWishlistHandler()}
                      style={{
                        marginLeft: "2rem",
                        color: "#686763",
                        fontSize: "0.7rem",
                      }}
                    >
                      Add to Wishlist
                    </Button>
                  </div>
                )}
                <div
                  className="variants"
                  style={{
                    paddingLeft: "1rem",
                    marginLeft: "1rem",
                  }}
                >
                  {shouldShowPrice && lowestPrice && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "2rem",
                        fontSize: "1rem",
                      }}
                    >
                      <div
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}
                        id="selectedPriceDisplay"
                      >
                        Price: ₹{lowestPrice && lowestPrice}
                      </div>
                      <div
                        id="originalPriceDisplay"
                        style={{
                          textDecoration: "line-through",
                          color: "#696663",
                          paddingLeft: "1rem",
                          fontSize: "1rem",
                        }}
                      >
                        Price: ₹{product?.min_max_price?.max_price}
                      </div>

                      <div className="discount">
                        {Math.round(
                          ((product.min_max_price.max_price - lowestPrice) /
                            product.min_max_price.max_price) *
                            100
                        )}
                        % Off
                      </div>
                    </div>
                  )}

                  {shouldShowPrice && !lowestPrice && (
                    <div style={{ paddingTop: "2rem" }}>
                      Add it to wishlist and we'll notify you once in stock
                    </div>
                  )}
                  <Rating
                    name="read-only"
                    value={parseInt(product.rating)}
                    readOnly
                  />
                </div>
              </CardContent>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Link to={"/product/" + product.id}>
                <CardMedia
                  component="img"
                  sx={{ width: 151, padding: "1rem" }}
                  image={product.image}
                  alt={product.name}
                  style={{ maxWidth: "226px" }}
                />
              </Link>
            </Grid>
          </Grid>
        </Card>
      </div>
    </>
  );
};

export default SearchCard;
