import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../App";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import CheckoutButton from "./PreOrderCheckout";
import { useDispatch, useSelector } from "react-redux";
import AddAddressCard from "../User/AddAddressCard";
import AddressCard from "../User/AddressCard";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { loadUser, updateUser } from "../../actions/userAction";

const EventDetails = () => {
  const userId = useSelector((state) => state.user?.user?.id);
  const navigate = useNavigate();

  console.log("userId", userId);
  const [eventDetails, setEventDetails] = useState(null);
  const [productDetails, setProductDetails] = useState(null);
  const [quantities, setQuantities] = useState({});
  const [avlblwallet, setAvlblwallet] = useState({});
  const { id } = useParams(); // Assuming this is the ID you want to fetch details for
  const WaitingForPaymentRef = useRef(false);

  const [checkoutButtonText, SetCheckoutButtonText] = useState("Checkout");

  useEffect(() => {
    if (!userId) {
      // navigate("/login");
      navigate("/login", { state: { from: window.location.pathname } });
    }
  }, [userId, navigate]);

  useEffect(() => {
    // Function to fetch event details
    const fetchEventDetails = async () => {
      try {
        const { data } = await axios.get(BASE_URL + `/getuserdata`, {
          withCredentials: true,
        });
        console.log("userdata", data?.userData?.data?.balance);
        setAvlblwallet(data?.userData?.data?.balance);
      } catch (error) {
        console.error("Error fetching event details:", error);
      }
    };

    fetchEventDetails();
  }, [id]);

  const userName = useSelector((state) => state.user?.user?.username);
  const userPhone = useSelector((state) => state.user?.user?.mobile);
  const userMail = useSelector((state) => state.user?.user?.email);
  const addresses = useSelector((state) => state.address?.address?.data);
  const scrollContainerRef = useRef(null);

  const [selectedAddress, setSelectedAddress] = useState(null);
  const [walletAmount, setWalletAmount] = useState(0);
  const [usedWalletAmount, SetUsedWalletAmount] = useState(0);

  const [grandtotal, SetGrandTotal] = useState(0);

  const handleWheel = (event) => {
    event.preventDefault();
    event.target.blur();
  };

  useEffect(() => {
    SetGrandTotal(subtotal - usedWalletAmount);
  }, [usedWalletAmount]);

  useEffect(() => {
    console.log("selectedAddress", selectedAddress);
  }, [selectedAddress]);

  const subtotal = productDetails?.data?.data.reduce((acc, product) => {
    const productTotal = product.variants.reduce((variantAcc, variant) => {
      const quantity = quantities[variant.id] || 0;
      const price = variant.special_price || variant.price;
      return variantAcc + parseInt(price, 10) * quantity;
    }, 0);
    return acc + productTotal;
  }, 0);

  // Directly calculate `finalTotal` to use in the component
  const finalTotal = subtotal - walletAmount;

  useEffect(() => {
    if (finalTotal == 0) {
      SetCheckoutButtonText("Place Order");
    } else {
      SetCheckoutButtonText("Make Payment and Place Order");
    }
  }, [finalTotal]);

  // Adjustment of wallet amount based on the user input and max possible application
  // const handleWalletAmountChange = (e) => {
  //   const newValue = Number(e.target.value);
  //   const maxInputAmount = Math.min(avlblwallet, subtotal); // Ensure wallet amount does not exceed subtotal or max wallet balance
  //   setWalletAmount(Math.min(newValue, maxInputAmount));
  // };

  const handleWalletAmountChange = (e) => {
    const newValue = Number(e.target.value);
    const maxInputAmount = Math.min(avlblwallet, subtotal); // Prevent exceeding balance or subtotal
    const newWalletAmount = Math.min(newValue, maxInputAmount);
    setWalletAmount(newWalletAmount);

    // Immediately adjust usedWalletAmount and grandTotal
    SetUsedWalletAmount(newWalletAmount);
    SetGrandTotal(Math.max(0, subtotal - newWalletAmount));
  };

  useEffect(() => {
    SetGrandTotal(subtotal);
  }, [subtotal]);

  // const avlblwallet = parseFloat(
  //   useSelector((state) => state.user?.user?.balance)
  // );

  useEffect(() => {
    console.log("quantities", quantities);
  }, [quantities]);

  const userData = {
    address_id: selectedAddress?.id,
    user_id: userId,
    mobile: userPhone,
    payment_method: "PhonePe",
  };
  useEffect(() => {
    // Function to fetch event details

    const fetchEventDetails = async () => {
      try {
        const event_response = await fetch(
          BASE_URL + `/app/middleware/v1/eventdetails/${id}`
        );
        const data = await event_response.json();
        // console.log("data",data.event.product_id)
        if (data && data.event) {
          // Assuming the API response has an 'events' field containing the details
          setEventDetails(data.event);
        }

        const apiUrl = BASE_URL + "/app/middleware/v1/getProductDetails";
        const ProductDetailsresponse = await axios.post(apiUrl, {
          id: data.event.product_id,
        });
        // console.log("ProductDetailsresponse",ProductDetailsresponse)
        setProductDetails(ProductDetailsresponse?.data);
      } catch (error) {
        console.error("Error fetching event details:", error);
      }
    };

    fetchEventDetails();
  }, [id]); // Dependency array, re-run this effect if the id changes

  useEffect(() => {
    console.log("productDetails", productDetails);
  }, [productDetails]);

  useEffect(() => {
    // Ensure wallet amount does not exceed the new subtotal or available wallet balance
    const newUsedWalletAmount = Math.min(walletAmount, avlblwallet, subtotal);
    SetUsedWalletAmount(newUsedWalletAmount);

    // Adjust grandTotal ensuring it does not go below zero
    const newGrandTotal = Math.max(0, subtotal - newUsedWalletAmount);
    SetGrandTotal(newGrandTotal);
  }, [subtotal, walletAmount, avlblwallet]);

  useEffect(() => {
    if (finalTotal < 0) {
      setWalletAmount(0);
    }
  }, [finalTotal]);

  useEffect(() => {
    // This part always runs, ensuring hooks are called in the same order
    if (productDetails && productDetails.data) {
      const initialQuantities = {};
      productDetails.data.data.forEach((product) => {
        product.variants.forEach((variant) => {
          initialQuantities[variant.id] = 0; // Set default quantity to 1
        });
      });
      setQuantities(initialQuantities);
    }
    // This dependency ensures the effect runs whenever productDetails changes
  }, [productDetails]);

  if (!eventDetails) {
    return <div>Loading event details...</div>;
  }

  // Initialize quantities state when product details are loaded

  const incrementQuantity = (variantId) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [variantId]: (prevQuantities[variantId] || 0) + 1,
    }));
  };

  const decrementQuantity = (variantId) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [variantId]: Math.max(0, (prevQuantities[variantId] || 1) - 1),
    }));
  };

  const renderProductVariants = (
    variants,
    quantities,
    decrementQuantity,
    incrementQuantity
  ) => {
    return (
      <Grid container spacing={2}>
        {variants.map((variant, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card
              style={{ marginBottom: "10px" }}
              sx={{
                borderRadius: "20px !important", // Adjust this value to control the roundness of the edges
                transition: "background-color 0.3s ease-out",
                backgroundColor:
                  quantities[variant.id] > 0 ? "#eecd5c" : "#f0f8ff",
                boxShadow: 0,
                "&:hover": {
                  backgroundColor: "#eecd5c !important",
                  // Change 'primary.main' to your desired hover color
                  // If you want a custom color that's not part of the theme, you can directly specify it, e.g., '#ff0000'
                },
              }}
            >
              <CardContent>
                <Typography variant="h5">{variant.variant_values}</Typography>
                <Typography>Price: {variant.price}</Typography>
                <Typography>Special Price: {variant.special_price}</Typography>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => decrementQuantity(variant.id)}
                    sx={{
                      color: "#6c757d",
                      borderColor: "#6c757d",
                      borderRadius: "10rem !important", // Adjust this value to control the roundness of the edges
                      "&:hover": {
                        backgroundColor: "#93bae9 !important", // Change 'primary.main' to your desired hover color
                        // If you want a custom color that's not part of the theme, you can directly specify it, e.g., '#ff0000'
                      },
                    }}
                  >
                    -
                  </Button>
                  <Typography style={{ margin: "0 10px" }}>
                    {quantities?.[variant.id] ?? "0"}
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={() => incrementQuantity(variant.id)}
                    sx={{
                      color: "#6c757d",
                      borderColor: "#6c757d",
                      borderRadius: "10rem !important", // Adjust this value to control the roundness of the edges
                      "&:hover": {
                        backgroundColor: "#93bae9 !important", // Change 'primary.main' to your desired hover color
                        // If you want a custom color that's not part of the theme, you can directly specify it, e.g., '#ff0000'
                      },
                    }}
                  >
                    +
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  // Example of displaying some details, adjust based on actual data structure
  return userId ? (
    <div>
      <Container>
        <div
          style={{
            backgroundColor: "#ccd5ae",
            // backgroundImage: 'url("https://img.freepik.com/free-vector/abstract-modern-gradient-background-with-grainy-texture-geometric-shapes_1188-792.jpg")',
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "cover",
            padding: "15px",
            paddingBottom: "30px",
            margin: "15px",
            borderRadius: "30px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            style={{ fontSize: "clamp(0.5rem, 0.3043rem + 3.4783vw, 2rem)" }}
          >
            Event Details
          </Typography>
          <Typography
            style={{ fontSize: "clamp(0.5rem, 0.3043rem + 3.4783vw, 1rem)" }}
          >
            <p>
              {eventDetails.title}
              <br />
              {eventDetails.sub_title}
              <br />
              <div
                dangerouslySetInnerHTML={{ __html: eventDetails.description }}
              />
            </p>
          </Typography>
          <Typography style={{}}>
            From {eventDetails.from_date} to {eventDetails.to_date}
          </Typography>
        </div>

        <div style={{ padding: "15px", margin: "15px" }}>
          <Divider>
            {" "}
            <Chip label="Pre-order" size="small" />
          </Divider>
          <Typography
            variant="h3"
            style={{
              fontSize: "clamp(0.5rem, 0.3043rem + 3.4783vw, 1.5rem)",
              paddingBottom: "20px",
            }}
          >
            Pre Order Now!
          </Typography>
          {productDetails &&
            productDetails.data.data.map((product, index) => (
              <div key={index}>
                {/* <Typography variant="h4">{product.name}</Typography> */}
                {renderProductVariants(
                  product.variants,
                  quantities,
                  decrementQuantity,
                  incrementQuantity
                )}
              </div>
            ))}

          <Box sx={{ display: "flex", gap: 2, my: 2 }}>
            <TextField
              style={{ width: "100%" }}
              label="Wallet Amount"
              type="number"
              variant="outlined"
              value={walletAmount}
              InputProps={{
                onWheel: (event) => handleWheel(event),
              }}
              onChange={(e) => {
                const newValue = Number(e.target.value);
                const maxInputAmount = Math.min(avlblwallet, subtotal);
                setWalletAmount(Math.min(newValue, maxInputAmount));
              }}
            />
            <Button
              variant="text"
              onClick={() => {
                SetUsedWalletAmount(walletAmount);
              }}
              style={{ width: "18rem" }}
            >
              Enter amount to use
            </Button>
          </Box>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              ref={scrollContainerRef}
              style={{
                display: "flex",
                flexDirection: "row",
                overflowX: "auto",
                flexWrap: "nowrap",
              }}
            >
              {addresses &&
                addresses.map((address) => (
                  <AddressCard
                    key={address?.id}
                    address={address}
                    isSelected={address?.id === selectedAddress?.id}
                    onSelect={() => setSelectedAddress(address)}
                  />
                ))}
              <AddAddressCard />
            </div>

            {/* <IconButton onClick={() => scroll(300)}>
            <ArrowForwardIosIcon />
          </IconButton> */}
          </div>

          {!selectedAddress?.id ? (
            <div style={{ color: "red", paddingBottom: "15px" }}>
              Please select one address!
            </div>
          ) : (
            ""
          )}

          <div style={{ color: "red", paddingBottom: "15px" }}>
            This is a self pickup product, you need to collect it from event. It
            will not be delivered
          </div>

          <div style={{ color: "red", paddingBottom: "15px" }}>
            Enable popups and refresh if not redirected to Payment Gateway
          </div>

          <Typography>Final Total: {finalTotal}</Typography>

          {selectedAddress ? (
            ""
          ) : (
            <div>
              <Button variant="contained" disabled>
                Checkout
              </Button>
            </div>
          )}
          {selectedAddress && userData && productDetails && quantities && (
            <CheckoutButton
              productDetails={productDetails}
              quantities={quantities}
              userData={userData}
              wallet_flg={walletAmount > 0 ? 1 : 0}
              wallet_balance={walletAmount}
              buttontext={checkoutButtonText}
              amounttopay={finalTotal}
              selectedAddress={selectedAddress}
            />
          )}

          <div style={{ padding: "2rem" }}></div>
        </div>
      </Container>
    </div>
  ) : (
    <div style={{ backgroundColor: "#fdf5e6" }}>
      <Container style={{ height: "50vw" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vw",
            flexDirection: "column",
          }}
        >
          <div style={{ padding: "10px" }}>
            <Typography>Please Login to access this Page!</Typography>
          </div>
          <div>
            <Button variant="contained" href="/login">
              Login/Register
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default EventDetails;
