import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { Button, Rating } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { modifyCart } from "../../actions/cartAction";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import "./Card.css";

const ProductCard = ({ product }) => {
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const variants = product?.variants;

  const modifyCartHandler = (userid, variantid) => {
    // console.log("add to cart button");

    const availableVariant = variants?.find(
      (variant) => parseInt(variant.availability) > 0
    );

    dispatch(modifyCart(userid, availableVariant?.id, 1));
    alert("Item added to cart");
    // dispatch(modifyCart(userid, variantid, 1));
  };

  // console.log("product", product.stock);

  const getLowestPrice = (variants) => {
    const availableVariants = variants.filter(
      (variant) => parseInt(variant.availability) > 0
    );
    if (availableVariants.length === 0) {
      return null;
    }
    return availableVariants.reduce((lowest, variant) => {
      return lowest.special_price < variant.special_price ? lowest : variant;
    }).special_price;
  };

  const lowestPrice = getLowestPrice(variants);

  // console.log( product);
  return (
    <>
      {product.availability != "0" && (
        <div>
          <Card
            style={{
              width: "12.5rem",
              height: "14rem",
              margin: "0.5rem",
              justifyContent: "center",
            }}
          >
            <Link
              to={`/product/${product.id}`}
              style={{ all: "unset", cursor: "pointer", margin: "0 auto" }}
            >
              <CardMedia
                component="img"
                alt={product.name}
                image={product.image}
                style={{
                  padding: "5px",
                  objectFit: "contain",
                  height: "8rem",
                  width: "8rem",
                  margin: "auto",
                }}
              />
            </Link>
            <CardContent style={{ padding: "9px" }}>
              <Typography
                noWrap
                variant="h6"
                component="div"
                style={{ fontSize: "12px" }}
              >
                {product.name}
              </Typography>
              <Rating
                name="read-only"
                value={parseFloat(product?.rating)}
                readOnly
              />
              <Typography
                variant="subtitle1"
                color="text.secondary"
                style={{ fontSize: "10px" }}
              >
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <div className="mainPrice">₹{lowestPrice}</div>
                  <div className="mrp">₹{product.min_max_price.min_price}</div>

                  <div className="discount">
                    {Math.round(
                      ((product.min_max_price.max_price - lowestPrice) /
                        product.min_max_price.max_price) *
                        100
                    )}
                    % Off
                  </div>
                  {!product?.name?.toLowerCase().includes("load the box") &&
                    user && (
                      <Button
                        style={{ padding: "0rem" }}
                        onClick={() =>
                          modifyCartHandler(user.id, product.variants[0].id)
                        }
                      >
                        <ShoppingCartIcon style={{ color: "#0f1111" }} />
                      </Button>
                    )}
                </div>
              </Typography>
              <div style={{ display: "flex" }}></div>
            </CardContent>
          </Card>
        </div>
      )}
    </>
  );
};

export default ProductCard;
