import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  Button,
  Dialog,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import { getProductDetails } from "../../actions/productAction";
import AddAddressCard from "../User/AddAddressCard";
import AddressCard from "../User/AddressCard";
import LinearLoader from "../layout/Loader/LinearLoader";
import CheckoutProductCard from "./checkoutProductCard";
import axios from "axios";
import { BASE_URL } from "../../App";

const BuyNow = () => {
  const navigate = useNavigate();
  const { productId, variantId } = useParams();
  const dispatch = useDispatch();

  // Redux data
  const { product, loading, error } = useSelector(
    (state) => state.productDetails
  );
  const addresses = useSelector((state) => state.address?.address?.data || []);
  const walletAmount = parseFloat(
    useSelector((state) => state.user?.user?.balance) || 0
  );
  const userId = useSelector((state) => state.user?.user?.id);

  // Local state
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [grandTotal, setGrandTotal] = useState(0);
  const [isWalletUsed, setIsWalletUsed] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [processingOrder, SetProcessingOrder] = useState(false);
  const [PaymentStatus, SetPaymentStatus] = useState("");
  const [merchantTransactionId, SetMerchantTransactionId] = useState("");
  const [merchantId, SetMerchantId] = useState("");
  const [checkoutButtonText, SetCheckoutButtonText] = useState("Loading...");
  const [open, setOpen] = useState(false);

  const processingOrderRef = useRef(false);
  const merchantIdRef = useRef("");
  const merchantTransactionIdRef = useRef("");
  const originalTransactionIdRef = useRef("");
  const orderObjectRef = useRef({});

  useEffect(() => {
    dispatch(getProductDetails(productId));
  }, [dispatch, productId]);

  useEffect(() => {
    const productObj = product?.data?.find((p) => p.id === productId);
    const selectedVariant = productObj?.variants?.find(
      (v) => v.id === variantId
    );
    if (selectedVariant) {
      setGrandTotal(parseFloat(selectedVariant.special_price || 0));
    }
  }, [product, variantId]);

  const handleWalletChange = (event) => {
    setIsWalletUsed(event.target.checked);
  };

  const calculateDeliveryCharges = (amount) => {
    if (amount < 100) return 100;
    if (amount < 500) return 150;
    if (amount < 999) return 200;
    return 0;
  };

  const deliveryCharge = calculateDeliveryCharges(grandTotal);
  const finalTotal = Math.max(
    0,
    grandTotal -
      discount +
      deliveryCharge -
      (isWalletUsed
        ? Math.min(walletAmount, grandTotal - discount + deliveryCharge)
        : 0)
  );

  const processOrder = async () => {
    const orderObject = orderObjectRef.current;

    if (
      (PaymentStatus === "Completed" || PaymentStatus === "PAYMENT_SUCCESS") &&
      orderObject.final_total !== 0 &&
      !processingOrderRef.current
    ) {
      processingOrderRef.current = true;
      SetProcessingOrder(true);
      try {
        const createOrder = await axios.post(
          `${BASE_URL}/app/middleware/v1/createorder`,
          orderObject
        );

        if (createOrder.data.error) {
          await axios.post(`${BASE_URL}/app/payment/v1/create-refund`, {
            merchantUserId: userId,
            originalTransactionId: originalTransactionIdRef.current,
            merchantTransactionId: merchantTransactionIdRef.current,
            amount: orderObject.final_total,
          });
          navigate(`/orderconfirmation/error`);
          return;
        }

        navigate(
          `/orderconfirmation/${createOrder?.data?.order_item_data[0]?.order_id}`
        );
      } catch (error) {
        console.error("Error in processOrder:", error);

        await axios.post(`${BASE_URL}/app/payment/v1/phonepe-refund`, {
          merchantUserId: userId,
          originalTransactionId: originalTransactionIdRef.current,
          merchantTransactionId: merchantTransactionIdRef.current,
          amount: orderObject.final_total,
        });
        navigate(`/orderconfirmation/error`);
      }
    }
  };

  const handleCheckout = async () => {
    SetProcessingOrder(true);
    setOpen(true);

    const walletAmountUsed = isWalletUsed
      ? Math.min(walletAmount, grandTotal - discount + deliveryCharge)
      : 0;

    orderObjectRef.current = {
      address_id: selectedAddress?.id,
      user_id: userId,
      mobile: selectedAddress?.mobile,
      product_variant_id: [variantId],
      quantity: [1],
      total: grandTotal,
      delivery_charge: deliveryCharge,
      tax_amount: 0,
      tax_percentage: 0,
      final_total: finalTotal,
      payment_method: "PhonePe",
      is_wallet_used: walletAmountUsed > 0 ? 1 : 0,
      wallet_balance_used: walletAmountUsed,
    };

    if (orderObjectRef.current.final_total === 0) {
      try {
        const createOrder = await axios.post(
          `${BASE_URL}/app/middleware/v1/createorder`,
          orderObjectRef.current
        );

        SetPaymentStatus("PAYMENT_SUCCESS");

        if (createOrder.data.error) {
          navigate(`/orderconfirmation/error`);
        } else {
          navigate(
            `/orderconfirmation/${createOrder?.data?.order_item_data[0]?.order_id}`
          );
        }
      } catch (error) {
        console.error("Error creating order:", error);
      }
    } else {
      console.log("Order Object:", orderObjectRef.current);
      try {
        const res = await axios.post(`${BASE_URL}/app/payment/v1/payment`, {
          name: orderObjectRef.current.user_id,
          amount: orderObjectRef.current.final_total,
          number: selectedAddress?.mobile,
          MUID: "MUID" + Date.now(),
          transactionId: "T" + Date.now(),
        });

        SetMerchantTransactionId(res.data.message.data.merchantTransactionId);
        SetMerchantId(res.data.message.data.merchantId);
        merchantIdRef.current = res.data.message.data.merchantId;
        merchantTransactionIdRef.current =
          res.data.message.data.merchantTransactionId;

        const url =
          res?.data?.message?.data?.instrumentResponse?.redirectInfo?.url;

        if (url) {
          window.open(url, "_blank");
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const checkPaymentStatus = async () => {
      if (merchantTransactionId) {
        try {
          const response = await axios.post(
            `${BASE_URL}/app/payment/v1/status`,
            {
              transactionId: merchantTransactionId,
              merchantId: merchantId,
            }
          );

          SetPaymentStatus(response?.data?.message?.code);
          // SettransactionId(response?.data?.message?.data?.transactionId);
          originalTransactionIdRef.current =
            response?.data?.message?.data?.transactionId;

          processOrder();
        } catch (error) {
          console.error("Error checking payment status:", error);
        }
      }
    };

    const intervalId = setInterval(checkPaymentStatus, 2000);
    return () => clearInterval(intervalId);
  }, [PaymentStatus, merchantTransactionId]);

  useEffect(() => {
    if (PaymentStatus === "PAYMENT_SUCCESS") {
      SetCheckoutButtonText("Payment Received");
    }
  }, [PaymentStatus]);

  if (loading) return <LinearLoader />;
  if (error || !product) {
    return (
      <Container>
        <Typography variant="h5" color="error">
          Unable to fetch product details.
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Checkout
      </Typography>

      <Dialog
        open={open}
        BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.5)" } }}
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            padding: 4,
          }}
        >
          <CircularProgress />
          <Typography variant="h6">
            Processing Payment, Please Wait...
          </Typography>
        </DialogContent>
      </Dialog>

      {product && <CheckoutProductCard product={product?.data?.[0]} />}

      <Box sx={{ my: 2 }}>
        <Typography variant="h6">Select Address:</Typography>
        <Box sx={{ display: "flex", overflow: "auto" }}>
          <AddAddressCard />
          {addresses.map((addr) => (
            <AddressCard
              key={addr.id}
              address={addr}
              isSelected={selectedAddress?.id === addr.id}
              onSelect={() => setSelectedAddress(addr)}
            />
          ))}
        </Box>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", gap: 2, my: 2 }}>
        <FormControlLabel
          control={
            <Checkbox checked={isWalletUsed} onChange={handleWalletChange} />
          }
          label="Use Wallet"
        />
        <Typography variant="body1">
          Available: ₹{walletAmount.toFixed(2)}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", my: 1 }}>
        <Typography variant="h6">Product Price:</Typography>
        <Typography variant="h6">₹{grandTotal.toFixed(2)}</Typography>
      </Box>

      {discount > 0 && (
        <Box sx={{ display: "flex", justifyContent: "space-between", my: 1 }}>
          <Typography variant="h6">Discount:</Typography>
          <Typography variant="h6">-₹{discount.toFixed(2)}</Typography>
        </Box>
      )}

      <Box sx={{ display: "flex", justifyContent: "space-between", my: 1 }}>
        <Typography variant="h6">Delivery Charge:</Typography>
        <Typography variant="h6">₹{deliveryCharge.toFixed(2)}</Typography>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
        <Typography variant="h6">To Pay:</Typography>
        <Typography variant="h6">₹{finalTotal.toFixed(2)}</Typography>
      </Box>

      <Button
        variant="contained"
        color="primary"
        onClick={handleCheckout}
        disabled={!selectedAddress?.mobile}
      >
        Checkout
      </Button>
    </Container>
  );
};

export default BuyNow;
